//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'Careers_Postings',
};
